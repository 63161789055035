import Layout from "../../common/components/Layout"
import Header from '../../common/components/Header'
import Footer from '../../common/components/Footer'
import themes from '../../common/themes'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const Wrapper = styled.div`
  width: 100%;
  margin-top: -80px;
`

const Container = styled.div`
  min-height: ${({ minHeight }) => minHeight || '100vh'};
  width: 100%;
  background-image: linear-gradient(transparent 90%, white 100%), url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`

const Section = styled.div`
  display: flex;
  justify-content: center;
`

const ContactCard = styled.form`
  max-width: 1140px;
  padding: 20px;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
`

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  margin: 10px auto;
`

function Contact() {
  const [data, setData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    const message = `${data.message}%0A%0ABest Regard,%0A${data.name}%0A${data.email}`
    window.location.href = `mailto:info@allnaturalindonesia.com?subject=${data.subject}&body=${message}`
  }

  return (
    <Layout header={{ component: <Header isNotTransparent /> }} footer={{ component: <Footer /> }}>
      <Wrapper>
        <Container style={{ paddingTop: '20vh' }}>
          <h1 style={{ textAlign: 'center' }}>Contact Us</h1>
          <h2 style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)' }}>All Natural Indonesia</h2>
          <Section>
            <ContactCard onSubmit={handleSubmit}>
              <Input type="text" placeholder="Name" required onChange={e => setData({ ...data, name: e.target.value })} />
              <Input type="email" placeholder="Email" required onChange={e => setData({ ...data, email: e.target.value })} />
              <Input type="text" placeholder="Subject" required onChange={e => setData({ ...data, subject: e.target.value })} />
              <textarea placeholder="Message" id="message" name="message" form="contactUs" style={{ width: '100%', resize: 'none' }} rows="10" required onChange={e => setData({ ...data, message: e.target.value })} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button type="submit" style={{ color: themes.colors.white, backgroundColor: themes.colors.primary, padding: '10px 50px', marginTop: '5%', cursor: 'pointer' }}>Send Message</button>
              </div>
            </ContactCard>
          </Section>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Contact
