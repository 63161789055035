import Home from '../pages/home'
import About from '../pages/about-us'
import Contact from '../pages/contact-us'
import Product from '../pages/product'

export default [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/about-us',
    component: About,
  },
  {
    path: '/contact-us',
    component: Contact,
  },
  {
    path: '/product',
    component: Product,
  },
]
