import { createElement } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import routes from './index'
import history from './history'

export default function RouterConfig() {
  return (
    <BrowserRouter history={history}>
      <Switch>
        {routes.map(route => {
          return createElement(Route, {
            path: route.path,
            component: route.component,
            exact: true,
          })
        })}
      </Switch>
    </BrowserRouter>
  )
}
