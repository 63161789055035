import Layout from "../../common/components/Layout"
import Header from '../../common/components/Header'
import Footer from '../../common/components/Footer'
import styled from 'styled-components'
import vanillaPaste from '../../common/assets/product/vanilla-paste.png'
import vanillaTahiti from '../../common/assets/product/tahiti.jpg'
import vanillaExtract from '../../common/assets/product/vanilla-extract.jpg'
import vanillaPlanifolia from '../../common/assets/product/planifolia.jpg'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'


const Wrapper = styled.div`
  width: 100%;
  margin-top: -80px;
`

const Container = styled.div`
  min-height: ${({ minHeight }) => minHeight || '100vh'};
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`

const ProductSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ProductContainer = styled.div`  
  max-width: 1140px;
  display: block;
  position: relative;
  width: 100%;
  padding: 10rem 0;

  > div {
    width: 100%;
    position: relative;
  }
`

const ProgressBar = styled.div`
  width: 80%;
  border: 1px solid brown;
  position: relative;

  > div {
    background-color: brown;
    width: ${({ width }) => width};
    height: 100%;
    position: absolute;
    top: 0;
  }
`

const ProductCard = styled.div`
  display: block;
  padding: 2rem;
  width: 100%;

  > div {
    h1,h3 {
      text-align: ${({ textAlign }) => textAlign};
    }

    img {
      display: flex;
      justify-content: center;
    }
  }

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: 2rem 0;

    > div {
      h1,h3 {
        text-align: center;
      }

      &:first-child {
        ${({ textAlign }) => textAlign === 'right' ? 'display: none;' : ''}
      }
  
      &:last-child {
        ${({ textAlign }) => textAlign === 'right' ? 'display: block;' : ''}
      }
    }
  }
`

const ProductImage = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;

  @media screen and (max-width:768px) {
    img {
      width: 100%;
    }
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    display: block;
    padding: 0;
  }
`

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout header={{ component: <Header isNotTransparent /> }} footer={{ component: <Footer /> }}>
      <Wrapper>
        <Container style={{ alignItems: 'center', display: 'flex' }}>
          <ProductSection>
            <ProductContainer>
              <Section>
                <ProductCard>
                  <ProductImage>
                    <img src={vanillaTahiti} />
                  </ProductImage>
                  <div>
                    <h2>Vanilla Tahiti</h2>
                    <p>Tahiti vanilla is a different experience entirely. It is extremely complex
                      and is used in Tahitian cooking for not only sweet confections but also savory
                      dishes. Tahitian vanilla extract is luxurious, intensely aromatic, exotic and
                      even sensual. The flavor profile has notes of sweetness, cherry, and spice
                    </p>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                  <ProgressBar width="1.2%">
                    <p style={{ textAlign: 'center', margin: '0 auto' }}>Vanilla Content</p>
                    <div></div>
                  </ProgressBar>
                  <span>1.2%</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                  <ProgressBar width="25%">
                    <p style={{ textAlign: 'center', margin: '0 auto' }}>Moisture</p>
                    <div></div>
                  </ProgressBar>
                  <span>20%-30%</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                  <ProgressBar width="0.05%">
                    <p style={{ textAlign: 'center', margin: '0 auto' }}>Length</p>
                    <div></div>
                  </ProgressBar>
                  <span>14-18 cm</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                  <ProgressBar width="100%">
                    <p style={{ textAlign: 'center', margin: '0 auto', color: 'white' }}>Gourmet</p>
                    <div style={{zIndex: -1}}></div>
                  </ProgressBar>
                </div>
                  </div>
                </ProductCard>
                <ProductCard>
                  <ProductImage>
                    <img src={vanillaPlanifolia} />
                  </ProductImage>
                  <div>
                    <h2 style={{ width: '100%' }}>Vanilla Planifolia</h2>
                    <p style={{ width: '100%' }}>Indonesia Vanilla planifolia is a species of vanilla orchid. It is native to Mexico, the Caribbean, northern South America, and Central America, and is one of the primary sources for vanilla flavouring, due to its high vanillin content.</p>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                      <ProgressBar width="1.5%">
                        <p style={{ textAlign: 'center', margin: '0 auto'}}>Vanilla Content</p>
                        <div></div>
                      </ProgressBar>
                      <span>1.5%</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                      <ProgressBar width="25%">
                        <p style={{ textAlign: 'center', margin: '0 auto' }}>Moisture</p>
                        <div></div>
                      </ProgressBar>
                      <span>20%-30%</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                      <ProgressBar width="0.07%">
                        <p style={{ textAlign: 'center', margin: '0 auto' }}>Length</p>
                        <div></div>
                      </ProgressBar>
                      <span>16-22 cm</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                      <ProgressBar width="100%">
                        <p style={{ textAlign: 'center', margin: '0 auto', color: 'white' }}>Gourmet</p>
                        <div style={{ zIndex: -1 }}></div>
                      </ProgressBar>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '5px auto' }}>
                      <ProgressBar width="100%">
                        <p style={{ textAlign: 'center', margin: '0 auto', color: 'white' }}>No split and no mold</p>
                        <div style={{ zIndex: -1 }}></div>
                      </ProgressBar>
                    </div>
                  </div>
                </ProductCard>
              </Section>
              <Section>
                <ProductCard>
                  <ProductImage>
                    <img src={vanillaPaste} />
                  </ProductImage>
                  <div>
                    <h2>Vanilla Paste</h2>
                    <p>Vanilla bean paste is a rich, thick paste that contains a blend of the
                      scraped-out vanilla pod seeds and vanilla extract. It is a great option if
                      you can’t find vanilla bean pods or you feel like the pods are too much work.
                      You still get the classic, rich vanilla flavor that the pod offers . It is
                      not always easy to find vanilla bean paste, but if a recipe calls for vanilla
                      and you can’t find the pods or extract for any reason, vanilla bean paste is
                      a great vanilla extract substitute (especially for vanilla frosting, custard
                      or ice cream).
                    </p>
                  </div>
                </ProductCard>
                <ProductCard>
                  <ProductImage>
                    <img src={vanillaExtract} />
                  </ProductImage>
                  <div>
                    <h2>Natural Vanilla Extract</h2>
                    <p>
                      The extract is made by soaking vanilla beans in water and an alcohol-based solution where
                      it ages for several months, during which time the vanilla flavor is extracted from the bean.
                      Vanilla extract or single strength vanilla extract is a stronger, liquid form of vanilla
                      flavoring and is often used to flavor brownies, cookies and a variety of cakes.  Extracts can
                      be stored indefinitely without losing much flavor. Keep the extract in a cool dry area away
                      from heat and direct light in an air tight sealed container.
                    </p>
                  </div>
                </ProductCard>
              </Section>
            </ProductContainer>
          </ProductSection>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default withRouter(About)