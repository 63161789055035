export default {
  colors: {
    primary: '#133d1d',
    white: '#ffffff',
    black: '#000000'
  },
  zIndex: {
    header: 9,
    navBar: 9,
    menuBar: 8,
  }
}
