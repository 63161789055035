import theme from "../../themes";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import logo from '../../../logo.png'

const StyledHeader = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
  top: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${theme.zIndex.header};
`;

const Container = styled.div`
  width: 1140px;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  color: ${({ color }) => color};
  align-items: center;
  padding: 0px 15px;
  z-index: ${theme.zIndex.navBar};
`;

const StyledLink = styled.a`
  background-image: ${logo};
  background-position: contain;
  display: inherit;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 350px;
  align-items: center;
  z-index: ${theme.zIndex.menuBar};

  a {
    color: ${({ color }) => color};
    text-decoration: none;
    cursor: pointer;

    &.active {
      padding: 30px 0px;
      border-bottom: 3px solid green;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Hamburger = styled.div`
  width: 30px;
  height: 16px;

  &.active {
    > div {
      background: black;
    }
  }

  > div {
    background: ${({ color }) => color};
    width: 100%;
    height: 2px;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const MenuResponsive = styled.div`
  display: block;
  max-height: 0px;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 80px 0 20px;
  transition: max-height 0.2s ease;
  
  &.active {
    max-height: 180px;
    transition: max-height 0.2s ease;
    background-color: white;

    a {
      display: block;
      opacity: 1;
      animation: opacities 0.2s;

      @keyframes opacities {
        from { opacity: 0 }
        to { opacity: 1 }
      }
    }
  }

  &.deactive {
    height: 0px;
    animation: heights 2s;

    a {
      display: none;
      opacity: 0;
      animation: opacities 0.2s;

      @keyframes opacities {
        from { opacity: 1 }
        to { opacity: 0 }
      }
    }

    @keyframes heights {
      from { height: 140px }
      to { height: 0px }
    }
  }

  > a {
    text-decoration: none;
    color: black;
    display: none;

    > div {
      text-align: center;
      padding: 1rem 0;
    }
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`

function BasicHeader({
  backgroundColor = theme.colors.white,
  boxShadow = "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
  children = null,
  color = theme.colors.black,
  justifyContent = "center",
  isNotTransparent = false,
}): JSX.Element {
  const [environment, setEnvironment] = useState({
    backgroundColor: "transparent",
    color: theme.colors.white,
    boxShadow: "0 0 0 1px rgba(255,255,255,0.3)",
  });
  const [expand, setExpand] = useState(false)

  const handleScroll = (): void => {
    const offset = window.scrollY;
    if (offset > 10) {
      setEnvironment({
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
      });
    } else {
      if (!isNotTransparent) {
        setEnvironment({
          backgroundColor: "transparent",
          color: theme.colors.white,
          boxShadow: "0 0 0 1px rgba(255,255,255,0.3)",
        });
      }
    }
  };

  useEffect(() => {
    if (isNotTransparent) {
      setEnvironment({
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
      });
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <StyledHeader
        boxShadow={environment.boxShadow}
        backgroundColor={environment.backgroundColor}
        color={environment.color}
      >
        <Container justifyContent="space-between">
          <Link to="/">
            <StyledLink>
              <img src={logo} style={{height: '50px'}}/>
            </StyledLink>
          </Link>
          <Menu color={environment.color}>
            <Link to="/">Home</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/product">Product</Link>
            <Link to="/contact-us">Contact Us</Link>
          </Menu>
          <Hamburger className={expand ? 'active' : ''} color={environment.color} onClick={() => setExpand(!expand)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </Hamburger>
        </Container>
        <MenuResponsive className={expand ? 'active' : 'deactive'}>
          <Link to="/">
            <div>Home</div>
          </Link>
          <Link to="/about-us">
            <div>About Us</div>
          </Link>
          <Link to="/product">
            <div>Our Product</div>
          </Link>
          <Link to="/contact-us">
            <div>Contact Us</div>
          </Link>
        </MenuResponsive>
      </StyledHeader>
    </>
  );
}

export default BasicHeader;
