import Layout from "../../common/components/Layout"
import Header from '../../common/components/Header'
import Footer from '../../common/components/Footer'
import styled from 'styled-components'
import Containers from '../../common/assets/shipping/container.jpg'
import Cargo from '../../common/assets/shipping/air-cargo.jpg'
import koi from '../../common/assets/partners/KOI.jpeg'
import honeysHealthyHive from '../../common/assets/partners/honeys-healthy-hive.jpeg'
import nigitaDessertBar from '../../common/assets/partners/nigita-dessert-bar.jpeg'
import dailyBaguette from '../../common/assets/partners/daily-baguette.jpeg'
import gustoGelatoCaffe from '../../common/assets/partners/gusto-gelato-caffe.jpeg'
import ilvero from '../../common/assets/partners/ilvero.jpeg'
import monsieurSpoon from '../../common/assets/partners/monsieur-spoon.jpeg'
import albaRistorante from '../../common/assets/partners/alba-ristorante.jpeg'
import donBakeshop from '../../common/assets/partners/don-bakeshop.jpeg'
import kanvazPatisserie from '../../common/assets/partners/kanvaz-patisserie.jpg'
import laRivieraRistorante from '../../common/assets/partners/la-riviera-ristorante.jpeg'
import vivoliGelato from '../../common/assets/partners/vivoli-gelato.jpg'
import sevenD from '../../common/assets/partners/seven-d.jpeg'
import { withRouter } from 'react-router-dom'
import logo from '../../logo.png'
import { useEffect } from 'react'


const Wrapper = styled.div`
  width: 100%;
  margin-top: -80px;
`

const Container = styled.div`
  min-height: ${({ minHeight }) => minHeight || '100vh'};
  width: 100%;
  background-image: linear-gradient(transparent 90%, white 100%), url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`

const ScrollDown = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    > span {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
  }

  > span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    &:nth-child(2)
    {
      animation-delay: -0.2s;
    }

    &:nth-child(3)
    {
      animation-delay: -0.4s;
    }
  }

  @keyframes animate{
	
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px, -20px);
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: rotate(45deg) translate(20px, 20px);
	}
}
`

const ShippingContain = styled.div`
  max-width: 1140px;
`

const ShippingCard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 400px;

  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;

    > img {
      width: 100%;
      transition: .5s;
      object-fit: cover;
    }
  }

  .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 75%;
    background: rgba(0, 0, 0, 0.7);
    transition: .5s;
    transform-origin: left;
    transform: perspective(20000px) rotateY(-90deg);
    color: white;
    padding: 3rem;
  }

  &:hover {
    .image > img {
      opacity: .5;
      transform: translateX(30%);
    }

    .details {
      transform: perspective(2000px) rotateY(0deg);
    }
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10rem;
`

const ContactContain = styled.div`
  width: 50%;

  > p {
    text-align: justify;
  }
`

const ClientContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  padding-bottom: 8rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 10rem;
  }
`

const ClientImages = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    display: block;
    padding-bottom: 0;
  }
`

const ClientImage = styled.div`
  display: flex;
  align-items: center;
  width: 313px;
  justify-content: center;
  box-shadow: 0 30px 90px 0 rgba(117, 117, 117, 0.15);
  margin: 0 15px;

  > img {
    width: 313px;
    height: 110px;
    object-fit: scale-down;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0 auto;

    > img {
      width: 100%;
      height: auto;
    }
  }
`

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout header={{ component: <Header isNotTransparent /> }} footer={{ component: <Footer /> }}>
      <Wrapper>
        <Container style={{ alignItems: 'center', display: 'flex' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{ width: '50%', height: '100%' }} src={logo} alt="All Natural Indonesia" />
          </div>
          <a href="#description">
            <ScrollDown>
              <span></span>
              <span></span>
              <span></span>
            </ScrollDown>
          </a>
        </Container>
        <Container style={{ display: 'flex', justifyContent: 'center', paddingTop: '25vh' }} id="description" minHeight="75vh">
          <ContactContain>
            <p>
              In the beginning, our company was engaged in manufacturing where we processed derivative 
              products. <b>All Natural Indonesia</b> is a privately owned company with a broadly diversified 
              customer base. Our dedicated production and quality control teams have tirelessly worked 
              to deliver the best quality products expected by our customers. Supported by more than 15 
              employees and more than 80 farmers, and best technology for our industry. Ultimately, it 
              is our top priority to meet our customers’s satisfaction and expectation.
            </p>
            <p>
              <b>All Natural Indonesia</b> is one of the leading producer of vanilla bean  product in Indonesia. 
              We have been established for over 2 years in derivative products. We are producing vanilla 
              bean ( Tahiti and Planifolia ), vanilla paste, and vanilla extract.
            </p>
            <p>
              Our company also continues to collaborate with several other companies abroad to help 
              distribute goods to be much better. At present our company has cooperated with more than 
              10 companies in 5 countries.
            </p>
            <p>
              Our company works with several International Shipping to answer consumer needs. With the 
              best quality service and also affordable prices for each selected shipping route.
            </p>
            <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '2rem' }}>Our Shipping Method</p>
          </ContactContain>
          <a href="#shipping">
            <ScrollDown>
              <span></span>
              <span></span>
              <span></span>
            </ScrollDown>
          </a>
        </Container>
        <Container style={{ paddingTop: '20vh' }} id="shipping">
          <Section style={{ paddingTop: '0' }}>
            <ShippingContain>
              <ShippingCard>
                <div class="image">
                  <img src={Containers} alt="shipphing with container logistic" />
                </div>
                <div class="details">
                  <h2>Container</h2>
                  <p>
                    Our company works with several International Shipping to answer consumer needs. With 
                    the best quality service and also affordable prices for each selected shipping route.
                  </p>
                </div>
              </ShippingCard>
            </ShippingContain>
          </Section>
        </Container>
        <Container style={{ paddingTop: '10vh' }} minHeight="1vh">
          <Section style={{ paddingTop: '0' }}>
            <ShippingContain>
              <ShippingCard>
                <div class="image">
                  <img src={Cargo} alt="shipping with air cargo" />
                </div>
                <div class="details">
                  <h2>Air Cargo</h2>
                </div>
              </ShippingCard>
            </ShippingContain>
          </Section>
        </Container>
        <Container style={{ paddingTop: '20vh' }} minHeight="25vh">
          <Section>
            <ClientContainer>
              <h1 style={{ textAlign: 'center' }}>our client</h1>
              <ClientImages>
                <ClientImage>
                  <img src={honeysHealthyHive} alt="honey healthy hive" />
                </ClientImage>
                <ClientImage>
                  <img src={koi} alt="KOI" />
                </ClientImage>
                <ClientImage>
                  <img src={nigitaDessertBar} alt="nigita dessert bar" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={dailyBaguette} alt="daily baguette" />
                </ClientImage>
                <ClientImage>
                  <img src={gustoGelatoCaffe} alt="gusto gelato caffe" />
                </ClientImage>
                <ClientImage>
                  <img src={ilvero} alt="ilvero" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={monsieurSpoon} alt="monsieur spoon" />
                </ClientImage>
                <ClientImage>
                  <img src={albaRistorante} alt="Alba Ristorante" />
                </ClientImage>
                <ClientImage>
                  <img src={laRivieraRistorante} alt="La Riviera Ristorante" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={donBakeshop} alt="Don Bakeshop" />
                </ClientImage>
                <ClientImage>
                  <img src={vivoliGelato} alt="Vivoli Gelato" />
                </ClientImage>
                <ClientImage>
                  <img src={kanvazPatisserie} alt="Kanvaz Patisserie" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={sevenD} alt={`Seven Days Kitchen & Bar`} />
                </ClientImage>
              </ClientImages>
            </ClientContainer>
          </Section>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default withRouter(About)