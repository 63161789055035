import styled from 'styled-components'
import wood from '../../assets/background/wood.jpg'

const Wrapper = styled.div`
  width: 100%;
  // background-image: linear-gradient(white 1%, transparent 15%), url('${wood}');
  // background-repeat: no-repeat;
  // background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  position: relative;
  background-color: black;

  > p {
    position: absolute;
    bottom: 0;
    color: white;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 4rem 0;
  color: white;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: block;
    padding: 4rem 20px;
  }
`

const Section = styled.div`
  width: ${({ width }) => width};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

function Footer(): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <Section width="40%">
          <h3>ALL NATURAL INDONESIA</h3>
          <p style={{ textAlign: 'justify' }}>All Natural Indonesia is one of the leading producer of vanilla bean  product in Indonesia. We have been established for over 2 years in derivative products. We are producing vanilla bean <br />( Tahiti and Planifolia ), vanilla paste, and vanilla extract.</p>
        </Section>
        <Section width="40%">
          <h3>CONTACT DETAILS</h3>
          <p style={{ fontWeight: 'bold' }}>Mobile:</p>
          <p>+62 81294783228</p>
          <p style={{ fontWeight: 'bold' }}>Email:</p>
          <p>info@allnaturalindonesia.com</p>
        </Section>
      </Container>
      <p>{`Copyright ⓒ ${new Date().getFullYear()} by All Natural Indonesia`}</p>
    </Wrapper>
  )
}

export default Footer
